import React, { useEffect } from "react";

const Home = () => {

    const goDown = () => {
        window.scrollBy(0, window.innerHeight);
        document.getElementById("page").style.backgroundColor = "transparent";
    }

    window.addEventListener("wheel", (e) => {
        if (window.innerWidth > 768) {
            var direction = 0;
            if (e.deltaY > 0) {
                direction = 1;
            } else {
                direction = -1;
            }
            window.scrollBy(0, window.innerHeight * direction);
            if (window.scrollY < window.innerHeight / 2) {
                document.getElementById("page").style.backgroundColor = "var(--light)";
            }
            else {
                document.getElementById("page").style.backgroundColor = "transparent";
            }
        }
    });

    window.addEventListener("touchmove", (e) => {
        if (window.innerWidth > 768) {
            var direction = 0;
            if (e.deltaY > 20) {
                direction = 1;
            } else {
                if (e.deltaY < -20) {
                    direction = -1;
                }
                else {
                    direction = 0;
                }
            }
            window.scrollBy(0, window.innerHeight * direction + (window.innerHeight - (window.scrollY % window.innerHeight)));
            if (window.scrollY < window.innerHeight / 2) {
                document.getElementById("page").style.backgroundColor = "var(--light)";
            }
            else {
                document.getElementById("page").style.backgroundColor = "transparent";
            }
        }
    });

    // Constantly check for y position on the page
    window.addEventListener("scroll", () => {
        if (window.scrollY < 3 * window.innerHeight / 4) {
            document.getElementById("page").style.backgroundColor = "var(--light)";
        }
        else {
            document.getElementById("page").style.backgroundColor = "transparent";
            var pageHeight = document.body.scrollHeight - window.innerHeight;
            if (window.scrollY > pageHeight - 10 && document.getElementById("value3").innerHTML !== "100%") {
                for (let i = 0; i <= 100; i++) {
                    setTimeout(() => {
                        document.getElementById("value1").innerHTML = i + "%";
                        document.getElementById("value2").innerHTML = i + "%";
                        document.getElementById("value3").innerHTML = i + "%";
                        document.getElementById("value1").style.filter = "saturate(" + i + "%)";
                        document.getElementById("value2").style.filter = "saturate(" + i + "%)";
                        document.getElementById("value3").style.filter = "saturate(" + i + "%)";
                        document.getElementById("circle1").style.filter = "saturate(" + i + "%)";
                        document.getElementById("circle2").style.filter = "saturate(" + i + "%)";
                        document.getElementById("circle3").style.filter = "saturate(" + i + "%)";
                    }, 17 * i);
                }
            }
            else {
                if (window.scrollY < pageHeight - 100) {
                    document.getElementById("value1").innerHTML = "0%";
                    document.getElementById("value2").innerHTML = "0%";
                    document.getElementById("value3").innerHTML = "0%";
                    document.getElementById("value1").style.filter = "saturate(0%)";
                    document.getElementById("value2").style.filter = "saturate(0%)";
                    document.getElementById("value3").style.filter = "saturate(0%)";
                    document.getElementById("circle1").style.filter = "saturate(0%)";
                    document.getElementById("circle2").style.filter = "saturate(0%)";
                    document.getElementById("circle3").style.filter = "saturate(0%)";
                }
            }
        }
    });

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div id="page">
            <div className="first">
                <h1 data-aos="zoom-in" data-aos-duration="1000">Sny Technologies</h1>
                <p data-aos="zoom-in" data-aos-duration="2000">Innovative • Creative • Secure • Efficient • Intuitive</p>
                <div className="scroll-down" onClick={goDown}>
                    <i className="fa fa-angle-down"></i>
                </div>
            </div>
            {
                window.innerWidth > 768 ? (
                    <div className="container">
                        <div data-aos="fade-right" data-aos-duration="1000" className="child">
                            <div className="box">
                                <h2>Our Services</h2>
                                <div className="services">
                                    <div className="service">
                                        <i className="fa fa-code"></i>
                                        <h3>Web3 Development</h3>
                                        <p>
                                            <h4>Web3 development gets you ready for the future of the internet.</h4>
                                            You need to build a personalised website for your web3 business ? <a href="https://calendly.com/sunil-g/30min" target="_blank" rel="noopener noreferrer">Book a meeting</a> with us to discuss it.
                                        </p>
                                    </div>
                                    <div className="service">
                                        <i className="fa fa-mobile"></i>
                                        <h3>Mobile Development</h3>
                                        <p>
                                            <h4>Mobile app development elevates your web3 project to the next level.</h4>
                                            Your web3 project needs a mobile app to reach a wider audience ? <a href="https://calendly.com/sunil-g/30min" target="_blank" rel="noopener noreferrer">Book a meeting</a> with us to discuss it.
                                        </p>
                                    </div>
                                    <div className="service">
                                        <i className="fa fa-btc"></i>
                                        <h3>Blockchain development</h3>
                                        <p>
                                            <h4>Blockchain development instantly gives your project credibility and security.</h4>
                                            You have a project idea and would like to know if it is realistic ? <a href="https://calendly.com/sunil-g/30min" target="_blank" rel="noopener noreferrer">Book a meeting</a> with us to discuss it.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div data-aos="fade-left" data-aos-duration="1000" className="child">
                            <div className="box">
                                <h2>Get in touch</h2>
                                <p>Book a meeting with us to discuss your project.</p>
                                <a href="https://calendly.com/sunil-g/30min" target="_blank" rel="noopener noreferrer" className="button main">
                                    Book a meeting
                                    <i className="fa fa-angle-right arrow-icon"></i>
                                </a>
                                <div className="social">
                                    <a href="https://www.linkedin.com/in/sunil-goulamhoussen/" target="_blank" rel="noopener noreferrer" className="button light">
                                        <i className="fa fa-linkedin"></i>
                                        Sunil Goulamhoussen
                                    </a>
                                    <a href="mailto:contact@snytechnologies.com" target="_blank" rel="noopener noreferrer" className="button light">
                                        <i className="fa fa-envelope-open"></i>
                                        contact@snytechnologies.com
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <>
                        <div className="container">
                            <div data-aos="zoom-in" data-aos-duration="1000" className="child">
                                <div className="box">
                                    <h2>Our Services</h2>
                                    <div className="services">
                                        <div className="service">
                                            <i className="fa fa-code"></i>
                                            <h3>Web3 Development</h3>
                                            <p>
                                                <h4>Web3 development gets you ready for the future of the internet.</h4>
                                                You need to build a personalised website for your web3 business ? <a href="https://calendly.com/sunil-g/30min" target="_blank" rel="noopener noreferrer">Book a meeting</a> with us to discuss it.
                                            </p>
                                        </div>
                                        <div className="service">
                                            <i className="fa fa-mobile"></i>
                                            <h3>Mobile Development</h3>
                                            <p>
                                                <h4>Mobile app development elevates your web3 project to the next level.</h4>
                                                Your web3 project needs a mobile app to reach a wider audience ? <a href="https://calendly.com/sunil-g/30min" target="_blank" rel="noopener noreferrer">Book a meeting</a> with us to discuss it.
                                            </p>
                                        </div>
                                        <div className="service">
                                            <i className="fa fa-btc"></i>
                                            <h3>Blockchain development</h3>
                                            <p>
                                                <h4>Blockchain development instantly gives your project credibility and security.</h4>
                                                You have a project idea and would like to know if it is realistic ? <a href="https://calendly.com/sunil-g/30min" target="_blank" rel="noopener noreferrer">Book a meeting</a> with us to discuss it.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container">
                            <div data-aos="zoom-in" data-aos-duration="1000" className="child">
                                <div className="box">
                                    <h2>Get in touch</h2>
                                    <p>Book a meeting with us to discuss your project.</p>
                                    <a href="https://calendly.com/sunil-g/30min" target="_blank" rel="noopener noreferrer" className="button main">
                                        Book a meeting
                                        <i className="fa fa-angle-right arrow-icon"></i>
                                    </a>
                                    <div className="social">
                                        <a href="https://www.linkedin.com/company/snytechnologies" target="_blank" rel="noopener noreferrer">
                                            <i className="fa fa-linkedin"></i>
                                            @snytechnologies
                                        </a>
                                        <a href="mailto:contact@snytechnologies.com" target="_blank" rel="noopener noreferrer">
                                            <i className="fa fa-envelope"></i>
                                            contact@snytechnologies.com
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )
            }
            <div className="container">
                <div className="scores">
                    <div className="score">
                        <i className="fa fa-tachometer"></i>
                        <h3>Performance</h3>
                        <p>Our productions are fast and efficient.</p>
                        <div className="circle_bar_animation">
                            <div className="progress">
                                <div className="progress-bar" style={{ width: "100%" }} id="circle1"></div>
                                <span className="progress-value" id="value1">0%</span>
                            </div>
                        </div>
                    </div>
                    <div className="score">
                        <i className="fa fa-lightbulb-o"></i>
                        <h3>Creativity</h3>
                        <p>We are always looking for new ways to innovate.</p>
                        <div className="circle_bar_animation">
                            <div className="progress">
                                <div className="progress-bar" style={{ width: "100%" }} id="circle2"></div>
                                <span className="progress-value" id="value2">0%</span>
                            </div>
                        </div>
                    </div>
                    <div className="score">
                        <i className="fa fa-shield"></i>
                        <h3>Security</h3>
                        <p>We make sure your data is safe.</p>
                        <div className="circle_bar_animation">
                            <div className="progress">
                                <div className="progress-bar" style={{ width: "100%" }} id="circle3"></div>
                                <span className="progress-value" id="value3">0%</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;